<template>
  <div class="inline">
    <LazySharedDrawer ref="drawer" @close="onClose">
      <div class="flex flex-col gap-4">
        <h3 class="text-2xl leading-tight">haelsi Vorteilsmitgliedschaft</h3>
        <template v-if="price && reducedPrice && savings && discount">
          <div class="flex flex-col rounded-2xl border border-gray-200 bg-brick-50 p-4">
            <div class="flex items-center justify-between">
              <h4 class="text-sm text-brick-700">dein Termin</h4>
              <AppPill class="self-start rounded-lg bg-green-100 text-xs">Spare {{ discount }}%</AppPill>
            </div>
            <div class="flex items-center gap-2">
              <p class="text-2xl font-medium text-gray-700">{{ transformToCurrency(reducedPrice) }}</p>
              <p class="font-medium text-red-600 line-through">{{ transformToCurrency(price) }}</p>
            </div>
            <p class="text-sm text-green-600">{{ transformToCurrency(savings) }} gespart</p>
            <p class="mt-2 text-sm">
              Du bist dir nicht sicher? Überzeuge dich auf der
              <AppLink to="/abo?mtm_campaign=quick-checkout" target="_blank">Mitgliedschaft Seite</AppLink>.
            </p>
          </div>
        </template>
        <SharedMembershipBox
          :price="BASIC_YEARLY_PRICE"
          title="Basic Mitgliedschaft"
          link="https://haelsi.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-EUR-Yearly&subscription_items[quantity][0]=1&layout=in_app">
          <ul>
            <AppListItem type="checkmark">25% Rabatt auf alle Arztbesuche</AppListItem>
            <AppListItem type="checkmark">Im Schnitt 45% Kassenrückerstattung</AppListItem>
            <AppListItem type="checkmark">VIP-Support über Telefon & Chat</AppListItem>
          </ul>
        </SharedMembershipBox>
        <SharedMembershipBox
          :price="PLUS_YEARLY_PRICE"
          title="Plus Mitgliedschaft"
          link="https://haelsi.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Plus-EUR-Monthly&subscription_items[quantity][0]=1&layout=in_app">
          <ul class="list-inside list-disc">
            <AppListItem type="checkmark">Alles von Basic, plus</AppListItem>
            <AppListItem type="checkmark">30% Rabatt auf jeden Therapietermin</AppListItem>
          </ul>
        </SharedMembershipBox>
        <SharedMembershipBox
          :price="RUNDUM_YEARLY_PRICE"
          :highlight="true"
          title="Rundum Mitgliedschaft"
          link="https://haelsi.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=rundum-trial-EUR-Monthly&subscription_items[quantity][0]=1&layout=in_app">
          <ul class="list-inside list-disc">
            <AppListItem type="checkmark">Alles von Basic, plus</AppListItem>
            <AppListItem type="checkmark">Umfassender haelsi Vorsorgecheck</AppListItem>
          </ul>
        </SharedMembershipBox>
      </div>
    </LazySharedDrawer>
  </div>
</template>

<script setup lang="ts">
import { BASIC_YEARLY_PRICE, PLUS_YEARLY_PRICE, RUNDUM_YEARLY_PRICE } from "~/constants/membership";

const membershipDrawerStore = useMembershipDrawerStore();
const price = computed(() => membershipDrawerStore.price);
const discount = computed(() => membershipDrawerStore.discount);

const reducedPrice = computed(() =>
  price.value && discount.value ? roundToPrecision(price.value * (1 - discount.value / 100), 2) : undefined,
);
const savings = computed(() =>
  price.value && reducedPrice.value ? roundToPrecision(price.value - reducedPrice.value, 2) : undefined,
);

membershipDrawerStore.$subscribe(
  (_mutation, state) => {
    if (state.isVisible === true) {
      handleOpen();
    }
  },
  { detached: true },
);
const drawer = ref();
function handleOpen(): void {
  drawer.value?.open();
}
function onClose(): void {
  membershipDrawerStore.close();
}
</script>

<style scoped></style>
