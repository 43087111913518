<template>
  <div class="flex flex-col gap-4 rounded-2xl border border-brick p-4">
    <div class="flex items-center gap-4">
      <AppPill class="flex items-center gap-1 self-start bg-brick-300">
        <SvgoDiamond class="h-4 w-4" />
        Kostenlos im 1. Monat
      </AppPill>
      <AppPill v-if="highlight" class="bg-lavender">&#x1F49B; beliebt </AppPill>
    </div>
    <p class="text-base text-gray-700">{{ title }}</p>
    <div>
      <p class="flex items-center gap-1 text-2xl font-normal leading-normal">
        {{ transformToCurrency(price) }} <span class="text-xs text-gray-600">/ Monat</span>
      </p>
      <p class="text-xs leading-normal text-gray-600">Das entspricht {{ transformToCurrency(yearlyPrice) }} pro Jahr</p>
    </div>
    <div>
      <slot />
    </div>
    <AppButton :to="link"> Gratis Probemonat starten </AppButton>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  price: number;
  title: string;
  link: string;
  highlight?: boolean;
}>();

const yearlyPrice = computed(() => props.price * 12);
</script>

<style scoped></style>
